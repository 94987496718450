import styled from "styled-components"

import { getSensorValue } from "src/data/devices/logic/deviceLogic"
import { ISensorReading } from "src/data/guestAccess/types/guestAccessTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { brandText, divider } from "src/ui/colors"
import HumidityIcon from "src/ui/icons/humidity.svg"
import TemperatureIcon from "src/ui/icons/temperature.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import {
  formatTemperatureString,
  formatTemperatureUnitString,
} from "src/utils/l10n"

export function SensorReadingsCard({
  temperature,
  humidity,
}: {
  temperature?: ISensorReading
  humidity?: ISensorReading
}) {
  const { t, langKeys } = useTranslate()
  const temperatureUnit = navigator.language === "en-US" ? "F" : "C"

  return (
    <ReadingsWrapper>
      <Reading>
        <Label>
          <IconWrapper>
            <TemperatureIcon />
          </IconWrapper>
          <MText variant="bodyS" color="secondary">
            {t(langKeys.temperature)}
          </MText>
        </Label>
        <MText variant="heading2">
          <span>
            {temperature?.value
              ? String(
                  formatTemperatureString(
                    temperature?.value,
                    temperatureUnit,
                    1
                  )
                )
              : `- ${formatTemperatureUnitString(temperatureUnit)}`}
          </span>
        </MText>
      </Reading>
      <Reading>
        <Label>
          <IconWrapper>
            <HumidityIcon />
          </IconWrapper>
          <MText variant="bodyS" color="secondary">
            {t(langKeys.humidity)}
          </MText>
        </Label>
        <MText variant="heading2">
          {humidity ? getSensorValue(humidity) : 0} %
        </MText>
      </Reading>
    </ReadingsWrapper>
  )
}

const borderRadius = "5px"

const IconWrapper = styled.div`
  width: 35px;
`

const ReadingsWrapper = styled.div`
  margin: ${spacing.M} 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid ${divider};
  border-radius: ${borderRadius};
  background-color: ${divider};
  gap: 1px;
`

const Reading = styled.div`
  background-color: white;
  gap: ${spacing.M};
  display: grid;
  grid-row: 1;
  padding: ${spacing.M};
  &:first-child {
    border-radius: ${borderRadius} 0 0 ${borderRadius};
  }
  &:last-child {
    border-radius: 0 ${borderRadius} ${borderRadius} 0;
  }
`
const Label = styled.div`
  display: flex;
  white-space: nowrap;
  color: ${brandText};
`
