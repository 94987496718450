import styled from "styled-components"

import { SensorReadingsCard } from "src/components/GuestAccess/SensorReadingsCard"
import { getUrlParamValue } from "src/data/filters/filtersUrl"
import { useFetchGuestAccessData } from "src/data/guestAccess/queries/guestAccessQueries"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"

export function GuestAccess() {
  const guestToken = getUrlParamValue("token") ?? ""

  const fetchGuestPageData = useFetchGuestAccessData({ token: guestToken })

  if (fetchGuestPageData.isLoading) {
    return (
      <CircularProgressWrapper>
        <MCircularProgress />
      </CircularProgressWrapper>
    )
  }

  if (!guestToken || !fetchGuestPageData.data) {
    return (
      <Box>
        <Greeting>No such guest found</Greeting>
        <InfoBox>
          This link will only work during your stay at the property.
        </InfoBox>
      </Box>
    )
  }

  return (
    <Box>
      <Greeting>Welcome {fetchGuestPageData.data.guest_name}!</Greeting>

      <SensorReadingsCard
        temperature={fetchGuestPageData.data.temperature}
        humidity={fetchGuestPageData.data.humidity}
      />

      <InfoBox>
        This property is equipped with a Minut sensor for your comfort and
        safety.
      </InfoBox>
      <ReadMore href="#">Read more</ReadMore>
    </Box>
  )
}

const CircularProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

const Greeting = styled.h1`
  text-align: center;
  margin-bottom: ${spacing.XS2};
`

const InfoBox = styled.p`
  text-align: center;
  margin-top: ${spacing.XS2};
`

const ReadMore = styled.a`
  text-align: center;
`

const Box = styled.div`
  max-width: 75ch;
  margin: ${spacing.XL} auto;
  padding: 0 ${spacing.XL};
  display: flex;
  flex-direction: column;
`
