import { useQuery } from "@tanstack/react-query"

import { API_DEFAULT } from "src/constants/minutApi"
import { GuestAccessData } from "src/data/guestAccess/types/guestAccessTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

function guestAccessCacheKeys() {
  return ["guestData"] as const
}

export function useFetchGuestAccessData({ token }: { token: string }) {
  async function fetchGuestAccessData() {
    const result = await minutApiHttpClient.get<GuestAccessData>(
      `${API_DEFAULT}/guest_access/${token}`
    )
    return result.data
  }

  return useQuery(guestAccessCacheKeys(), fetchGuestAccessData)
}
